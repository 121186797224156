import * as types from '../../actionTypes';

export const changeUIState = (partialState) => ({
  type: types.CHANGE_UI_STATE,
  payload: partialState,
});

export const resetOrderFormValidity = (partialState) => ({
  type: types.RESET_ORDER_FORM_VALIDITY,
  payload: partialState,
});

export const changeLocale = (locale) => ({
  type: types.CHANGE_LOCALE,
  payload: locale,
});

export const clearNotices = (noticesToClean) => ({
  type: types.CLEAR_NOTICES,
  payload: noticesToClean,
});
