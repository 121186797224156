import { handle } from 'redux-pack';
import { toCamelCase } from 'utils/IPUtils';
import * as types from '../../../actionTypes';

export default (state, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.PIA_FETCH_PRODUCTS_INSTANCES:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            productInstances: payload?.data?.product_instances
              ? toCamelCase(payload.data.product_instances)
              : [],
          };
        },
      });
    case types.PIA_FETCH_PRODUCT_INSTANCE_DETAILS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            productInstanceDetails: payload?.data,
          };
        },
      });
    case types.PIA_FETCH_PRIVATE_EQUITY_METRICS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            privateEquityMetrics: payload?.data
              ? { [payload.data.inv_number]: toCamelCase(payload.data) }
              : [],
          };
        },
      });
    default:
      return state;
  }
};
