import { getBasePath, getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getFinMenus = ({ customer }) => {
  const { productAccess } = customer;

  const basePath = getBasePath();

  const myInvestments = {
    id: 'myInvestments',
    title: 'Omat säästöni',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'Yleiskatsaus',
          href: getUrl(`/#/Dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Määräaikaistalletukset',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: 'Säästötilit',
          href: getUrl('/#/MyInvestments/Overnight'),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: 'Säästäminen',
    href: '',
    subMenus: {
      main: [
        {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Määräaikaistalletukset',
          description: 'Hyödynnä kiinteä korko valitulle ajanjaksolle',
          href: '/fi-fi/products/',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: 'Säästötilit',
          href: '/fi-fi/products/overnight/',
          description: 'Säästä ilman sitovaa määräaikaa ja vaihtuvalla korolla',
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
          groupName: 'Sparen',
        },
      ],
    },
  };

  const information = {
    id: '3f11a01f-4216-4fea-b05f-2fd46cf3b8b4',
    title: 'Mikä on Raisin',
    href: '',
    subMenus: {
      main: [
        {
          id: '9d4v781e-7180-4618-b581-38951a8b5f547',
          type: 'text',
          title: 'Tietoa meistä',
          href: `${basePath}/tietoa-raisinista/`,
        },
        {
          id: '9d4g882x-b191-46s8-b581-42551an8uf824',
          type: 'text',
          title: 'Näin Raisin toimii',
          href: `${basePath}/nain-raisin-toimii/`,
        },
        {
          id: '9d4g882x-b191-47s8-b581-426561an7uf824',
          type: 'text',
          title: 'Raisin Bank',
          href: `${basePath}/raisin-bank/`,
        },
      ],
    },
  };

  const help = {
    id: '7d61gcdc-4e33-4fe2-9c58-878a2r7534c6',
    title: 'Yleistä tietoa',
    href: '',
    subMenus: {
      main: [
        {
          id: '9d7v781e-728o-46i8-bs81-38951a1b2547',
          type: 'advanced',
          title: 'Kumppanipankit',
          description: 'Tutustu kumppanipankkeihimme',
          href: `${basePath}/pankki/`,
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltb30f24c72431a3ba/Partnerbanken.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '9d6h992y-2i91-56s8-b591-42721a7uf824',
          type: 'advanced',
          title: 'Talletussuoja',
          description: 'Kaikki Euroopan talletussuojajärjestelmästä',
          href: `${basePath}/talletussuoja/`,
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8e96e15c429c928b/Einlagensicherung.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '9d6g8810z-b100-4s78-b581-42551anvuf824',
          type: 'advanced',
          title: 'Verotus',
          description: 'Tietoja talletusten verotuksesta',
          href: `${basePath}/verotus/`,
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt30ff10945458f646/Belasting.svg',
          iconColor: '#0E6CC5',
        },
      ],
    },
  };

  const customerSupport = {
    id: '6e24gfac-3f12-5ab9-8d69-947b3f8546d7',
    title: 'Asiakastuki',
    href: '',
    subMenus: {
      main: [
        {
          id: '8a3b791f-829p-57j9-cf72-49062b2c3658',
          type: 'text',
          title: 'Kuinka avata Raisin-tili',
          href: `${basePath}/tilin-avaaminen/`,
        },
        {
          id: '8b4c902z-3k82-67t9-c682-53832c8vg935',
          type: 'text',
          title: 'Usein kysytyt kysymykset',
          href: 'https://asiakastuki.raisin.com/hc/fi-fi',
        },
        {
          id: '8c5d1131a-c211-5t89-c692-54673bnxvg935',
          type: 'text',
          title: 'Ota yhteyttä',
          href: `${basePath}/ota-yhteytta/`,
        },
      ],
    },
  };

  return [myInvestments, offers, information, help, customerSupport];
};
