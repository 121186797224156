export const getFinMenus = ({ menuLinks }) => [
  {
    id: '2c1d8255-3a4c-4d78-bf4b-f0f450ce9fff',
    type: 'text',
    title: 'Viestit',
    href: menuLinks.POSTBOX,
  },
  {
    id: '0e57aad3-f3fd-4f97-a438-3b96476dd20c',
    type: 'text',
    title: 'Asiakirjat',
    href: menuLinks.DOCUMENTS,
  },
  {
    id: '5534dc4e-fd1e-4936-84f1-0fe185ec6e82',
    type: 'text',
    title: 'Tilitapahtumat',
    href: menuLinks.TRANSACTIONS,
  },
  {
    id: '8fbeb352-f366-470f-aaca-bf93e53f2c47',
    type: 'text',
    title: 'Nostot',
    href: menuLinks.WITHDRAW,
  },
  {
    id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
    type: 'text',
    title: 'Kirjautumistiedot',
    href: menuLinks.USER_ACCOUNT,
  },
  {
    id: 'e81df68f-9700-45c1-b305-23beb9acc063',
    type: 'text',
    title: 'Omat tiedot',
    href: menuLinks.MY_DETAILS,
  },
  {
    id: '4681530d-9eed-4cbe-982d-5445410895d8',
    type: 'text',
    title: 'Ilmoitukset',
    href: menuLinks.NOTIFICATIONS,
  },
];

export const getFinMenusNonExportedCustomer = ({ menuLinks }) => {
  const navItems = [
    {
      id: '2c1d8255-3a4c-4d78-bf4b-f0f450ce9fff',
      type: 'text',
      title: 'Viestit',
      href: menuLinks.POSTBOX,
    },
    {
      id: '0e57aad3-f3fd-4f97-a438-3b96476dd20c',
      type: 'text',
      title: 'Asiakirjat',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Kirjautumistiedot',
      href: menuLinks.USER_ACCOUNT,
    },
  ];

  return navItems?.length ? navItems : undefined;
};
