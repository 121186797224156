import React, { Component, cloneElement } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LINKS } from 'components/Layout/menu';
import {
  fetchAccounts,
  fetchTamsTransactionAccount,
  fetchCASCustomerData,
  fetchCustomerValidityStatus,
} from 'store/customer/actions';
import { getRegionName, GBR_REGION } from 'utils/IPUtils';
import { isLoggedIn, getBACNumberFromJWT, getTransactionsAccountData } from 'utils/CustomerUtils';
import GtmTrackingSetup from 'utils/GtmTracking';
import { identifyUserBy } from '@raisin/events-tracking';

export class Authorization extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    default_address: PropTypes.object.isRequired,
    fetchTransactionAccount: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    fetchCustomerValidityStatus: PropTypes.func.isRequired,
  };

  state = {
    allDataLoaded: false,
  };

  componentDidMount() {
    const { fetchTransactionAccount, fetchAccountsDispatch, tamsTransactionAccount, history } =
      this.props;

    if (isLoggedIn()) {
      getTransactionsAccountData({
        fetchTransactionAccount,
        fetchAccountsDispatch,
        tamsTransactionAccount,
      });
    } else {
      history.push(LINKS.LOGIN);
    }
  }

  componentDidUpdate() {
    const {
      fetchCASCustomerData: fetchCustomerDataFromCAS,
      fetchCustomerValidityStatus: getCustomerValidityStatus,
      history,
    } = this.props;

    const { allDataLoaded } = this.state;

    if (!allDataLoaded) {
      const getCustomerData = fetchCustomerDataFromCAS;

      if (isLoggedIn()) {
        const bacNumber = getBACNumberFromJWT();

        getCustomerData(bacNumber).then(() => {
          this.setState({ allDataLoaded: true });

          if (getRegionName() !== GBR_REGION) {
            getCustomerValidityStatus(bacNumber);
          }
          identifyUserBy('bac_number', bacNumber);
        });
      } else {
        history.push(LINKS.LOGIN);
      }
    }
  }

  render() {
    const { children } = this.props;
    const { allDataLoaded } = this.state;

    if (!allDataLoaded) return null;

    return (
      <>
        <GtmTrackingSetup />
        {cloneElement(children, { ...this.state })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  default_address: state.customer.default_address,
  bac_number: state.customer.bac_number,
  tamsTransactionAccount: state.customer.tamsTransactionAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountsDispatch: (bacNumber) => dispatch(fetchAccounts(bacNumber)),
  fetchTransactionAccount: (bac_number) => dispatch(fetchTamsTransactionAccount(bac_number)),
  fetchCASCustomerData: (bacNumber) => dispatch(fetchCASCustomerData(bacNumber)),
  fetchCustomerValidityStatus: (bac_number) => dispatch(fetchCustomerValidityStatus(bac_number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Authorization));
