import { getBasePath, getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getComMenus = ({
  customer,
}) => {
  const { productAccess } = customer;

  const basePath = getBasePath();

  const myInvestments = {
    id: 'myInvestments',
    title: 'My savings',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'My dashboard',
          href: getUrl(`/#/dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Fixed term deposits',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: 'Demand deposit accounts',
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: 'Savings accounts',
    href: '',
    subMenus: {
      main: [
        {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Savings accounts',
          href: '/products/',
          description: 'Compare all the savings products we offer',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
      ],
    },
  };

  const information = {
    id: 'information',
    title: 'About',
    href: '',
    subMenus: {
      main: [
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc5',
          type: 'advanced',
          title: 'About us',
          href: `${basePath}/about-raisin/`,
          description: 'Everything you need to know about Raisin',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8c6cd341e43b5eb4/About_us.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '2a134210-7e96-4614-b940-318c010b7a0a',
          type: 'advanced',
          title: 'Banks',
          href: `${basePath}/bank/`,
          description: 'Find out more about our partner banks',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt389ea348700b16ca/Banks.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'd63e6408-738b-4d6f-927a-bc2274804d69',
          type: 'advanced',
          title: 'Interest Rate Tracker',
          href: `${basePath}/interest-rate-tracker/`,
          description: 'Comparison of each market’s top available interest rates by month',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt2a478fbbe527b59e/Interest_Rate_Tracker.svg',
          iconColor: '#0E6CC5',
        },
      ],
    },
  };

  return [myInvestments, offers, information];
};
